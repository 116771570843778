import React, { MouseEventHandler } from 'react';

import './PrivacyPolicy.scss';

type Props = {
  isOpen: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
}

export function PrivacyPolicy({ isOpen, onClose }: Props) {
  return isOpen
    ? (
      <div
        className="modal privacy-policy"
        role="dialog"
      >
        <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Privacy Notice
              </h5>
              <button
                aria-label="Close"
                className="btn close"
                data-dismiss="modal"
                onClick={onClose}
                type="button"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ overflowY: 'auto' }}>
              <p>
                The principal purpose for requesting the information used by this interactive automated messaging service is to provide you targeted material about health plans available to you as a University of California workforce member. Information is collected under the delegated authority of UCDH under the Health Services Committee of the Board of Regents.
              </p>
              <p>
              Furnishing the requested information is optional, but providing inaccurate or incomplete information will affect the targeted responses provided to you by this automated messaging service. Information provided to the automated messaging service is confidential and will only be transmitted to State and Federal governments as required by law. Individuals have the right to review their own records in accordance with University personnel policy and collective bargaining agreements.
              </p>
              <p>
                The official responsible for maintaining the information collected by this automated messaging service is:
              </p>
              <address className="privacy-policy__official-address">
                <b>Digital CoLab – Director of Innovation</b><br/>
                <a href="tel:+9163978941">916-397-8941</a><br/>
                1651 Alhambra Blvd<br/>
                Sacramento, CA 95816
              </address>
              <p>
                {'Health plan materials provided to you are for consideration purposes only and additional health plan details are available '}
                <span>
                  <a
                    href="https://ucnet.universityofcalifornia.edu/compensation-and-benefits/health-plans/medical/index.html"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    here
                  </a>
                </span>
                .
              </p>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={onClose}
                type="button"
              >
                Close
              </button>
            </div>
          </div>
      </div>
    )
    : null;
}
