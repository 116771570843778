import { useMemo, useState, useEffect } from 'react';
import ReactWebChat, {
  createDirectLine,
  createStore,
  StyleOptions,
} from 'botframework-webchat';
import { DirectLine } from 'botframework-directlinejs';

import logo from 'src/assets/img/brand_logo.png';
import * as bots from 'src/data/bots';
import './AzureWebChat.scss';

type ContainerProps = {
  botId: string;
  conversationParams?: { [key: string]: any };
  requestAgent: Function;
};

type Props = {
  conversationParams: { [key: string]: any };
  directLine: DirectLine;
  error: string | null;
  loading: boolean;
  requestAgent: Function;
};

export function AzureWebChat({
  conversationParams,
  directLine,
  error,
  loading,
  requestAgent,
}: Props) {
  const [isIncoming, setIsIncoming] = useState(false);
  const [expectedInput, setExpectedInput] = useState('');
  const [numOptions, setNumOptions] = useState(0);
  const [requestingAgent, setRequestingAgent] = useState(false);

  // Scroll to end of chat, if required after incoming activity
  useEffect(() => {
    if (isIncoming) {
      const scrollToBottomButton = document.getElementsByClassName(
        'webchat__scroll-to-end-button'
      ) as HTMLCollectionOf<HTMLElement>;
      if (scrollToBottomButton.length) scrollToBottomButton[0].click();
      setIsIncoming(false);
    }
  }, [isIncoming]);

  // Conditionally hide text box, based on expected input
  useEffect(() => {
    const sendBox = document.getElementsByClassName(
      'webchat__send-box__main'
    )[0] as HTMLElement;
    if (expectedInput === 'text') {
      sendBox.style.display = 'flex';
    } else {
      sendBox.style.display = 'none';
    }
  }, [expectedInput]);

  // Request an agent after a period of waiting
  useEffect(() => {
    if (requestingAgent) {
      const timer = setTimeout(() => {
        requestAgent();
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [requestAgent, requestingAgent]);

  const store = useMemo(
    () =>
      createStore(
        {},
        ({ dispatch }: { dispatch: any }) =>
          (next: any) =>
          (action: any) => {
            switch (action.type) {
              case 'DIRECT_LINE/CONNECT_FULFILLED':
                dispatch({
                  meta: { method: 'keyboard' },
                  payload: {
                    activity: {
                      channelData: {
                        postBack: true,
                      },
                      name: 'startConversation',
                      type: 'event',
                      value: {},
                      conversationParams,
                    },
                  },
                  type: 'DIRECT_LINE/POST_ACTIVITY',
                });
                break;
              case 'DIRECT_LINE/INCOMING_ACTIVITY':
                setIsIncoming(true);
                if (action.payload.activity.type === 'request-agent') {
                  setRequestingAgent(true);
                } else if (
                  action.payload.activity.inputHint === 'expectingInput'
                ) {
                  const options =
                    (action.payload.activity.suggestedActions || {}).actions
                    || [];
                  setExpectedInput(options.length ? 'options' : 'text');
                  setNumOptions(options.length);
                } else {
                  setExpectedInput('');
                }
                break;
              case 'DIRECT_LINE/POST_ACTIVITY':
                // Add conversation params
                action.payload.activity.conversationParams = conversationParams;
                break;
            }
            return next(action);
          }
      ),
    [conversationParams]
  );

  // Styling options for the bot.
  // https://bisser.io/bot-framework-v4-webchat-styling-options/
  const style = useMemo(
    () => ({
      hideUploadButton: true,
      // Avatar
      avatarSize: 40,
      botAvatarImage: logo,
      botAvatarInitials: '',
      botAvatarBackgroundColor: 'white',
      // Bubble
      bubbleBackground: 'rgb(2,40,81)',
      bubbleTextColor: 'white',
      bubbleBorderRadius: 18,
      bubbleBorderWidth: 0,
      bubbleFromUserBackground: '#007991',
      bubbleFromUserTextColor: 'white',
      bubbleFromUserBorderRadius: 18,
      bubbleFromUserBorderWidth: 0,
      bubbleNubSize: 0,
      sendBoxButtonColorOnFocus: '#fabd14',
      transcriptOverlayButtonBackgroundOnFocus: '#fabd14',
      // SuggestedActions
      suggestedActionHeight: '2.6rem',
      suggestedActionLayout: 'stacked',
      suggestedActionBorderStyle: 'solid',
      suggestedActionBorderRadius: 18,
      suggestedActionBorderColor: '#027991',
      suggestedActionTextColor: '#027991',
    }),
    []
  );
  const className = ['chatbot', numOptions > 4 ? 'chatbot--wrap' : '']
    .filter(Boolean)
    .join(' ');
  return (
    <div className={className}>
      {loading && <div className="chatbot__loading">A moment please...</div>}
      {error && (
        <div>{`There is a problem fetching the post data - ${error}`}</div>
      )}
      <ReactWebChat
        directLine={directLine}
        store={store}
        styleOptions={style as StyleOptions}
      />
    </div>
  );
}

export function AzureWebChatContainer({
  botId,
  conversationParams = {},
  requestAgent,
}: ContainerProps) {
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // Retrieve a session token
    const getDirectLine = async () => {
      try {
        const session = await bots.getSessionToken(botId);
        setToken(session.token);
      } catch (err) {
        setError('Could not connect to the chatbot');
      } finally {
        setLoading(false);
      }
    };
    getDirectLine();
  }, [botId]);

  const convoParams = useMemo(
    () => ({ ...conversationParams, botId }),
    [botId, conversationParams]
  );

  // Set up a DirectLine connection with the Chatbot service
  const directLine = useMemo(() => {
    return createDirectLine({ token });
  }, [token]);

  return (
    <AzureWebChat
      conversationParams={convoParams}
      directLine={directLine}
      error={error}
      loading={loading}
      requestAgent={requestAgent}
    />
  );
}
