// Chatbot API
export const API_BASE =
  process.env.REACT_APP_CHATBOT_API_BASE_URL || 'localhost:3001';
export const API_VERSION = process.env.REACT_APP_CHATBOT_API_VERSION || 'v1';
export const IS_SECURE = (process.env.REACT_APP_IS_SECURE || 'true') === 'true';

export const TWILIO_ACCOUNT_SID = '';
export const TWILIO_FLOW_SID = '';

type ApiMethod = 'GET' | 'POST';

enum StatusCode {
  OK = 200,
  CREATED = 201,
  SERVER_ERROR = 500,
}

export async function apiFetch(
  route: string,
  method: ApiMethod,
  data: any = null
) {
  // Build request objects
  const request: RequestInit = {
    method,
    headers: { 'Content-Type': 'application/json' },
  };
  if (data !== null && data !== undefined) request.body = JSON.stringify(data);
  // Submit request
  const scheme = IS_SECURE ? 'https' : 'http';
  const endpoint = `${scheme}://${API_BASE}/${API_VERSION}/${route}`;
  const response = await fetch(endpoint, request);
  switch (response.status) {
    // Handle expected case
    case StatusCode.OK:
    case StatusCode.CREATED:
      const responseBody = await response.json();
      if (responseBody.success) {
        return responseBody.result;
      } else {
        console.warn(`Request to ${endpoint} errored`, responseBody.message);
        throw new Error(responseBody.message);
      }
    // Handle server error
    case StatusCode.SERVER_ERROR:
      throw new Error(`Request to ${endpoint} failed - server error`);
  }
}
