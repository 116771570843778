import { useEffect } from 'react';

import * as baseData from 'src/data/base';
import './TwilioWebChat.scss';

// Imported in index.html
const Twilio = (window as any).Twilio;

type Props = {
  conversationId: string;
};

const TWILIO_CONFIG = {
  disableLocalStorage: true,
  header: { enabled: false },
  fileAttachment: { enabled: false },
  isFloating: false,
  serverUrl: `${baseData.IS_SECURE ? 'https' : 'http'}://${baseData.API_BASE}`,
  transcript: { enabled: false },
};

export function TwilioWebChat(props: Props) {
  const parentId = `twilio-root_${props.conversationId}`;
  useEffect(() => {
    Twilio.initWebchat({
      ...TWILIO_CONFIG,
      rootElementId: parentId,
      refreshPath: `/${baseData.API_VERSION}/conversations/${props.conversationId}/agent/refresh`,
      tokenPath: `/${baseData.API_VERSION}/conversations/${props.conversationId}/agent`,
    });
  }, [props.conversationId]);

  return <div className="bot-twilio" id={parentId}></div>;
}

export default TwilioWebChat;
