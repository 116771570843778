import { useEffect, useState } from 'react';

import logo from 'src/assets/img/brand_logo.png';
import { PrivacyPolicy } from '../PrivacyPolicy/PrivacyPolicy';
import { AzureWebChatContainer } from '../AzureWebChat/AzureWebChat';
import TwilioWebChat from '../TwilioWebChat/TwilioWebChat';
import './Home.scss';

export function Home() {
  const [botId, setBotId] = useState('');
  const [showAgent, setShowAgent] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  useEffect(() => {
    // Check that the URL matches a pre-existing bot
    const botId = (window.location.href || '').split('://')[1].split('/')[1];
    // Redirect the user to a default bot, if need be
    if (botId !== 'open-enrollment' && botId !== 'ucsf-phq9') {
      window.location.replace('/open-enrollment');
      return;
    }
    // Display the bot
    setBotId(botId);
    // Track click events for changes to the URL
    const trackUrlChange = () => {
      setShowPrivacyPolicy(
        window.location.href.indexOf('#privacy-policy') > -1
      );
    };
    document.body.addEventListener('click', trackUrlChange);
    // Clean up (on unmount)
    return () => {
      document.body.removeEventListener('click', trackUrlChange);
    };
  }, []);

  const handleClosePrivacyPolicy = () => {
    window.history.replaceState(null, '', window.location.href.split('#')[0]);
  };

  const handleOpenPrivacyPolicy = () => {
    window.history.replaceState(
      null,
      '',
      `${window.location.href.split('#')[0]}#privacy-policy`
    );
  };

  const handleRequestAgent = () => setShowAgent(true);

  return (
    <div
      className={['main', showAgent ? 'main--agent' : 'main--bot'].join(' ')}
    >
      <nav className="navbar heading">
        {/** The outer div is required for bootdstrap styling. */}
        <div className="navbar__main-body">
          <a
            className="navbar__image-container"
            href="https://health.ucdavis.edu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="header__image" src={logo} alt="UC Davis Health" />
          </a>
          <span className="navbar-brand mb-0 vertical__divider"></span>
          <h2 className="navbar-brand mb-0 header__text">
            Open Enrollment Chatbot
          </h2>
        </div>
        <button
          type="button"
          className="btn btn-link btn-privacy"
          onClick={handleOpenPrivacyPolicy}
        >
          Privacy
        </button>
      </nav>

      <div className="home__body">
        {showAgent && <TwilioWebChat conversationId="1234" />}
        {!showAgent && Boolean(botId) && (
          <AzureWebChatContainer
            botId={botId}
            requestAgent={handleRequestAgent}
          />
        )}
      </div>

      <PrivacyPolicy
        isOpen={showPrivacyPolicy}
        onClose={handleClosePrivacyPolicy}
      />
    </div>
  );
}
